<template>
    <v-container fluid fill-heigth class="table-content px-10">
        <v-row class="text-left align-center">
            <v-col cols="1" class="primary--text text-center"
                ><span class="no-break">{{ $t('leveling.beereaders_level') }}</span></v-col
            >
            <v-col cols="1" class="primary--text text-center"
                ><span class="no-break">{{ $t('leveling.complexity') }}</span></v-col
            >
            <v-col cols="6" class="primary--text text-center"
                ><span>{{ $t('leveling.description') }}</span></v-col
            >
            <v-col cols="2" class="primary--text text-center"
                ><span>{{ $t('leveling.spanish_lexile') }}</span></v-col
            >
            <v-col cols="2" class="primary--text text-center"
                ><span>{{ $t('leveling.fountas_pinnel') }}</span></v-col
            >
        </v-row>
        <v-divider />
        <div v-if="levelData.length">
            <v-container class="first-content mt-3">
                <v-row class="text-left" v-for="(row, index) of first_row" :key="index">
                    <v-col cols="1" class="text-center px-5"
                        ><span class="font-weight-regular">{{ row.level }}</span></v-col
                    >
                    <v-col class="text-center" cols="1"
                        ><span class="text-subtitle-1">{{ row.complexity_level }}</span></v-col
                    >
                    <v-col class="text-center" cols="6"
                        ><span class="text-body-1">{{ row.description }}</span></v-col
                    >
                    <v-col class="text-center" cols="2"
                        ><span class="text-subtitle-1">{{ row.lexile }}</span></v-col
                    >
                    <v-col class="text-center" cols="2"
                        ><span class="text-subtitle-1">{{ row.fountas_pinnell }}</span></v-col
                    >
                </v-row>
            </v-container>
            <v-container class="second-content mt-3">
                <v-row class="text-left" v-for="(row, index) of second_row" :key="index">
                    <v-col cols="1" class="px-5 text-center"
                        ><span class="font-weight-regular">{{ row.level }}</span></v-col
                    >
                    <v-col class="text-center" cols="1"
                        ><span class="text-subtitle-1">{{ row.complexity_level }}</span></v-col
                    >
                    <v-col class="text-center" cols="6"
                        ><span class="text-body-1">{{ row.description }}</span></v-col
                    >
                    <v-col class="text-center" cols="2"
                        ><span class="text-subtitle-1">{{ row.lexile }}</span></v-col
                    >
                    <v-col class="text-center" cols="2"
                        ><span class="text-subtitle-1">{{ row.fountas_pinnell }}</span></v-col
                    >
                </v-row>
            </v-container>
        </div>
        <v-container v-else class="text-center secondary--text">
            <span>{{ $t('not_data') }}</span>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'LevelReadingLegend',
    props: {
        levelData: { type: Array, default: () => [] },
    },
    computed: {
        first_row() {
            if (this.levelData.length) return this.levelData.slice(0, 3)
            return []
        },
        second_row() {
            if (this.levelData.length) return this.levelData.slice(3, 6)
            return []
        },
    },
}
</script>

<style scoped>
.table-content {
    box-sizing: border-box;
    border: 2px solid #e9e6f0;
    border-radius: 6px;
    background-color: #ffffff;
    margin: 0px 20px;
}

.first-content {
    border-radius: 6px;
    background-color: #fff6cb;
}

.second-content {
    border-radius: 6px;
    background-color: #ffebcb;
}

.no-break{
    width: 100px;
    display: block;
}
</style>
