<template>
    <div
        :class="`grade-selector-content pa-5 d-flex flex-column text-center cursor-pointer  ${
            active ? 'grade-active' : ''
        }`"
        @click="setActive"
    >
        <span class="text-title">{{ grade.name }}</span>
    </div>
</template>

<script>
export default {
    props: ['grade', 'active'],
    name: 'GradeSelector',
    methods: {
        setActive() {
            this.$emit('setActive')
        },
    },
}
</script>

<style scoped>
.grade-selector-content {
    box-sizing: border-box;
    border: 2px solid #e9e6f0;
    border-radius: 6px;
    background-color: #ffffff;
    color: #514773;
}

.grade-active {
    box-sizing: border-box;
    border: 2px solid #514773;
    border-radius: 6px;
    background-color: #514773 !important;
    color: #fff !important;
}
</style>
