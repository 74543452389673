<template>
    <v-row cols="12">
        <v-card elevation="5" class="pa-3 w-100 border-radius-1">
            <v-card-title class="text-h5 font-weight-bold py-1">{{
                $t('reporting.level_system')
            }}</v-card-title>
            <v-row class="px-2 mt-5">
                <v-row class="d-flex justify-center align-center" style="padding: 0 20px;">
                    <v-col cols="2" v-for="(grade, index) of grades" :key="index"
                        ><GradeSelector
                            :grade="grade"
                            :active="gradeActive === grade.name"
                            @setActive="setActiveGrade(grade.name)"
                    /></v-col>
                </v-row>
                <LevelReadingLegend :level-data="currentLevelData"/>
            </v-row>
        </v-card>
    </v-row>
</template>

<script>
import GradeSelector from './core/GradeSelector'
import LevelReadingLegend from './core/LevelReadingLegend'

export default {
    name: 'CardSkill',
    components: {
        GradeSelector,
        LevelReadingLegend,
    },
    data: () => ({
        currentLevelData: [],
        gradeActive: null,
    }),
    computed: {
        grades() {
            return [
                { name: this.$t('grades.first') },
                { name: this.$t('grades.second') },
                { name: this.$t('grades.third') },
                { name: this.$t('grades.fourth') },
                { name: this.$t('grades.fiveth') },
                { name: this.$t('grades.sixth') },
                { name: this.$t('grades.seventh') },
                { name: this.$t('grades.eighth') },
                { name: this.$t('grades.nineth') },
                { name: this.$t('grades.tenth') },
                { name: this.$t('grades.eleventh') },
                { name: this.$t('grades.twelveth') },
            ]
        },
    },
    watch: {
        gradeActive(newValue) {
            this.currentLevelData = this.$t('leveling.leveling_system_params').filter(
                (i) => i.grade === newValue,
            )
        },
    },
    created() {
        this.setActiveGrade(this.grades[0].name)
    },
    methods: {
        setActiveGrade(grade) {
            this.gradeActive = grade
        },
    },
}
</script>

<style scoped></style>
